import React from "react";
import { Grid } from "@nodeme/grid-react";
import Block from "./Shared/Block";
import { buildClasses, IDefinitions, ITheme } from "@nodeme/jss-react";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons/faSpinnerThird";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IProps as IAppProps } from "../App";

export interface IProps {
  mode: IAppProps["mode"];
  id: number;
  artist?: string;
  title?: string;
  thumb?: string | null;
  lot?: number;
  bid?: string;
  available?: number;
  onClick: () => void;
}
export interface IState {}

const style = (theme: ITheme) => (definition: IDefinitions) => ({
  lot: {
    color: theme.palette.get("primary"),
    padding: "0px 8px 0px 0px",
    fontSize: "3rem",
    lineHeight: "2rem",
    fontWeight: 600,
  },
  lineHolder: {
    position: "relative",
  },
  line: {
    position: "absolute",
    display: "block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: "100%",
    textOverflow: "ellipsis",
  },
  artist: {
    top: "-5px",
  },
  title: {
    top: "22px",
    color: theme.palette.get("grey"),
    fontSize: "0.8rem",
    fontWeight: 100,
  },
  thumb: {
    height: "220px",
    width: "calc(100% + 32px)",
    margin: "-16px -16px 16px -16px",
    backgroundColor: theme.palette.get("lightGrey"),
    color: theme.palette.get("primary"),
    textAlign: "center",
    fontSize: "4rem",
    paddingTop: "64px",
    boxSizing: "border-box",
    display: "block",
    backgroundPosition: "center",
    backgroundImage: (props: IProps) =>
      props.thumb ? `url("${props.thumb}")` : "none",
  },
  card: {
    cursor: "pointer",
    position: "relative",
  },
  badge: {
    fontSize: "0.8rem",
    position: "absolute",
    right: 0,
    top: 0,
    padding: "4px 8px",
    boxShadow: "0px 2px 5px 0px #000000",
  },
  primary: {
    backgroundColor: theme.palette.get("primary"),
    color: "#fff",
  },
  danger: {
    backgroundColor: theme.palette.get("danger"),
    color: "#fff",
  },
  success: {
    backgroundColor: theme.palette.get("success"),
    color: "#fff",
  },
});

export default function ArtworkCard(props: IProps) {
  const classes = buildClasses(style, { ...props });

  return (
    <div className={classes.card} onClick={props.onClick}>
      <Block>
        <div className={classes.thumb}>
          {!props.thumb && <FontAwesomeIcon icon={faSpinnerThird} spin />}
          {props.mode === "SELLING" && props.bid !== "0.00" && (
            <span className={classNames([classes.badge, classes.primary])}>
              {props.bid} €
            </span>
          )}
          {props.mode === "SHOWING" && props.available === 0 && (
            <span className={classNames([classes.badge, classes.danger])}>
              Verkauft
            </span>
          )}
          {props.mode === "SHOWING" && (props.available || 0) > 0 && (
            <span className={classNames([classes.badge, classes.success])}>
              Verfügbar
            </span>
          )}
        </div>
        <Grid>
          <Grid item xs="auto">
            <span className={classes.lot}>{props.lot || props.id}</span>
          </Grid>
          <Grid item xs="rest">
            <Grid>
              <Grid item>
                <div className={classes.lineHolder}>
                  <span className={classNames([classes.line, classes.artist])}>
                    {props.artist}
                  </span>
                </div>
              </Grid>
              <Grid item>
                <div className={classes.lineHolder}>
                  <span className={classNames([classes.line, classes.title])}>
                    {props.title}
                  </span>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Block>
    </div>
  );
}
